@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Cookie&family=Creepster&family=Dancing+Script&family=Lobster&family=Pacifico&family=Whisper&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;




h4 {
    font-weight: bold;
    text-align: center;
    color: white;
    margin: 0;
    line-height: 0;
    
    &:nth-of-type(2) {
        color: blue;
    };
}